<template>
  <div>
    <v-alert
      :type="actionClass"
      class="mr-15"
      v-if="message"
      dismissible
    >
      {{ message }}
    </v-alert>
    <v-list-item class="mt-n4" v-for="(file, index) in getUploadedFile" :key="index">
      <v-list-item-icon>
        <v-icon large class="mt-4">
          mdi-note
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ file.client_name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ file.file_size }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon class=" ">
        <v-btn icon tile :color="modalColor" class="mt-4 icon-inverted">
          <v-icon color="green" small> mdi-greater-than </v-icon>
        </v-btn>
        <label class="mt-6 ">
          {{ file.created_at }}
        </label>
      </v-list-item-icon>

      <v-list-item-action>
        <v-list-item-icon class="mt-5">
          <v-btn
            icon
            text
            outlined
            tile
            :color="modalColor"
            class="white--text"
            @click="removeFile(index, file.file_name)"
          >
            <!-- <v-icon color=""> mdi-delete </v-icon> -->
            <i class="far fa-trash-alt"></i>
          </v-btn>
        </v-list-item-icon>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import { mapMutations, mapGetters} from 'vuex';
import colorMxn from "@/mixins/colorMxn";
import validationMxn from "@/mixins/validation_mixin";

export default {
  name: "fileList",
  props: ["files"],
  mixins: [colorMxn, validationMxn],
  data() {
    return {
      actionClass: '',
      message: '',
    };
  },
  computed: {
    ...mapGetters(['getUploadedFile']),
  },
  methods: {
    ...mapMutations(['setRemoveUploadedFile']),
    async removeFile(key, fileName) {
      const payload = {
        file_name: fileName,
      }
      const fullPayload = {
        params: payload,
        endpoint: `/deleted`,
      };
      const response = await this.performPostActions(fullPayload);
      this.actionClass = response.data.status === "success" ? 'success': 'error';
      this.message = response.data.message;
      if (response.data.status === "success") {
        this.setRemoveUploadedFile(key);
      }
      
    },
  }
};
</script>

<style scoped>
.icon-inverted {
  transform: rotate(270deg);
}
</style>
